<template>
  <div
    :class="contentWidth === 'boxed' ? 'container p-0' : null"
    class="position-relative bg-primary"
  >
    <img
      src="@/assets/images/icons/path-1.png"
      alt="path-1"
      class="path"
    >
    <img
      src="@/assets/images/icons/path-2.png"
      alt="path-2"
      class="path"
    >
    <router-view />
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'

export default {
  setup() {
    const { contentWidth } = useAppConfig()
    return { contentWidth }
  },
}
</script>
